import { render, staticRenderFns } from "./FlujoRepartidor.vue?vue&type=template&id=72e1f192&scoped=true"
import script from "./FlujoRepartidor.vue?vue&type=script&lang=js"
export * from "./FlujoRepartidor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72e1f192",
  null
  
)

export default component.exports