<template>
  <div>
    <CRow class="mt-1">
      <CCol lg="4" xl="4" md="12" sm="12" xs="12">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span
              ><i class="fas fa-dolly"></i>&nbsp; DESPACHAR A REPARTIDORES</span
            >
            <button
              @click="mtdBack"
              type="button"
              class="btn btn-sm text-white"
              style="background: #926cdc"
            >
              <i class="fas fa-hand-point-left"></i> Retroceder
            </button>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="12" sm="12" md="12" lg="12" class="text-center">
                <h4>STOCK ACTUAL</h4>
              </CCol>
              <CCol xs="12" sm="12" md="12" lg="12">
                <ul class="list-group">
                  <li
                    v-for="(item, index) in stock"
                    :key="index"
                    :class="mtdGetClasesStock(index)"
                    @click="mtdSelectItemDetail(item, index)"
                  >
                    <b
                      >{{ item.product.name }} -
                      {{ item.product.type == 2 ? "" : item.type }}
                      {{ item.amount }}
                      {{ item.product.type == 2 ? "Uni" : "Kg" }}</b
                    >
                  </li>
                </ul>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol
        v-if="dispatch.detail.length > 0"
        lg="5"
        xl="5"
        md="12"
        sm="12"
        xs="12"
      >
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span
              ><i class="fas fa-search-dollar"></i>&nbsp; DETALLE DEL DESPACHO</span
            >
          </CCardHeader>
          <CCardBody>
            <CRow v-for="(item, index) in dispatch.detail" :key="index">
              <CCol xs="12" sm="12" md="6" lg="6"
                ><b
                  >{{ item.name }} -
                      {{ item.product=='TIENDA'?'':item.product }}
                      {{ item.amount }}
                      {{ item.type == 2 ? "Uni" : "Kg" }}</b
                >
              </CCol>
              <CCol xs="12" sm="12" md="3" lg="3">
                <CInput
                  label=""
                  size="sm"
                  placeholder="Cantidad"
                  v-model="item.quantity"
                  @keyup="mtdVerifQuantity(index)"
                  v-on:keypress="isNumber($event)"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol
        v-if="dispatch.detail.length > 0"
        lg="3"
        xl="3"
        md="12"
        sm="12"
        xs="12"
      >
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span><i class="fas fa-user"></i>&nbsp;REPARTIDORES</span>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="12" sm="12" md="12" lg="12">
                <ul class="list-group">
                  <li
                    v-for="(item, index) in dealer"
                    :key="index"
                    :class="
                      keyDealer != item.id
                        ? 'list-group-item list-group-item-primary mt-2'
                        : 'list-group-item list-group-item-action active mt-2'
                    "
                    @click="mtdSelectDealer(item.id)"
                  >
                    <b>{{ item.name }}</b>
                  </li>
                </ul>
              </CCol>
              <CCol xs="12" sm="12" md="6" lg="6" class="mt-2">
                <CButton class="w-100 btn btn-sm btn-danger">
                  <i class="fas fa-trash"></i>&nbsp;Cancelar
                </CButton>
              </CCol>
              <CCol xs="12" sm="12" md="6" lg="6" class="mt-2">
                <CButton
                  class="w-100 btn btn-sm btn-success"
                  :disabled="cpButtonSave"
                  @click="mtdSaveDispatch"
                >
                  <i class="fas fa-save"></i>&nbsp;Despachar
                </CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../../main";

export default {
  name: "c-process-dispatchtienda",
  data() {
    return {
      stock: [],
      dispatch: {
        dealer: null,
        detail: [],
      },
      keyStockSelected: [],
      keyDealer: null,
    };
  },
  created() {
    this.mtdGetdata();
  },
  computed: {
    cpButtonSave() {
      if (this.dispatch.detail.length > 0) {
        let aux = 0;
        this.dispatch.detail.forEach((element) => {
          if (element.quantity == 0) {
            aux++;
          }
        });
        if (aux == 0 && this.dispatch.dealer != null) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    mtdGetdata: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/process/dispatch/tienda/2/" +
          this.$store.getters.get_campu.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.stock = response.data.stock;
          this.dealer = response.data.dealer;
          this.keyStockSelected = [];
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtdGetClasesStock: function (pos) {
      let clases = "";
      let temp = this.keyStockSelected.filter((element) => element.key == pos);
      if (temp.length > 0) {
        clases = "list-group-item list-group-item-action active mt-2";
      } else {
        clases = "list-group-item list-group-item-primary mt-2";
      }
      return clases;
    },
    mtdVerifDetail: function (idVerif) {
      let boo = false;
      this.dispatch.detail.forEach((element) => {
        if (element.id == idVerif) {
          boo = true;
        }
      });
      return boo;
    },
    mtdSelectItemDetail: function (detail, pos) {
      if (!this.mtdVerifDetail(detail.id)) {
        this.keyStockSelected.push({
          key: pos,
        });
        let detItem = {
          id: detail.id,
          name: detail.product.name,
          product_id: detail.product.id,
          amount: detail.amount,
          type: detail.product.type,
          product:detail.type,
          quantity: 0,
        };
        this.dispatch.detail.push(detItem);
      } else {
        let tmp = [];
        this.keyStockSelected.forEach((element) => {
          if (element.key != pos) {
            tmp.push(element);
          }
        });
        this.keyStockSelected = tmp;
        tmp = [];
        this.dispatch.detail.forEach((element) => {
          if (element.id != detail.id) {
            tmp.push(element);
          }
        });
        this.dispatch.detail = tmp;
      } 
    },
    mtdSelectDealer: function (dealer) {
      this.dispatch.dealer = dealer;
      this.keyDealer = dealer;
    },
    mtdVerifQuantity: function (pos) {
      if (
        parseFloat(this.dispatch.detail[pos].quantity) > parseFloat(this.dispatch.detail[pos].amount)
      ) {
        bus.$emit("alert", {
          color: "warning",
          message: "No hay stock!!",
        });
        this.dispatch.detail[pos].quantity = 0;
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    mtdSaveDispatch: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/process/dispatch/tienda/store/" +
          this.$store.getters.get_campu.id,
        token: this.$store.getters.get__token,
        params: this.dispatch,
      })
        .then((response) => {
          this.dispatch= {
            dealer: null,
            detail: [],
          };
          this.keyStockSelected=[];
          this.keyDealer=  null;
          this.mtdGetdata();
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>