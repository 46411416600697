<template>
  <div>
    <CRow class="mt-1">
      <CCol lg="4" xl="4" md="12" sm="12" xs="12">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span><i class="fas fa-dolly"></i>&nbsp; DESPACHAR INSUMOS</span>
            <button
              @click="mtdBack"
              type="button"
              class="btn btn-sm text-white"
              style="background: #926cdc"
            >
              <i class="fas fa-hand-point-left"></i> Retroceder
            </button>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="12" sm="12" md="12" lg="12" class="text-center">
                <h4>STOCK ACTUAL</h4>
              </CCol>
              <CCol xs="12" sm="12" md="12" lg="12">
                <ul class="list-group">
                  <li
                    v-for="(item, index) in stock"
                    :key="index"
                    class="list-group-item list-group-item-info mt-2"
                    @click="mtdSelectItemDetail(item)"
                  >
                    <b>{{ item.product.name }} {{ item.type }}: {{ item.amount }}Kg</b>
                  </li>
                </ul>
              </CCol>
              <CCol xs="12" sm="12" md="12" lg="12" class="text-center mt-2">
                <!-- <h4>COMPRAS DEL DÍA: <b>{{ purchase }}Kg</b></h4> -->
              </CCol>
              <!-- <CCol xs="12" sm="12" md="12" lg="12" class="text-center">
                <h4>COMPRAS DEL DÍA</h4>
              </CCol>
              <CCol xs="12" sm="12" md="12" lg="12">
                <ul class="list-group">
                  <li
                    v-for="(item, index) in purchase"
                    :key="index"
                    :class="mtdGetClasesPurchase(item.state, index)"
                    @click="mtdSelectPurchase(item, index)"
                  >
                    {{ item.factura }} {{ item.provider.name }}
                  </li>
                </ul>
              </CCol> -->
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol
        lg="8"
        xl="8"
        md="12"
        sm="12"
        xs="12"
      >
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span
              ><i class="fas fa-bars"></i>&nbsp; DETALLE DE DESPACHO</span
            >
          </CCardHeader>
          <CCardBody>
            <CRow>
              <!-- <CCol xs="12" sm="12" md="12" lg="12">
                <div class="position-relative table-responsive">
                  <table class="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Producto</th>
                        <th>Prec. kg.</th>
                        <th>Cant. Unidades</th>
                        <th>Cant. Jabas</th>
                        <th>Cant. kg</th>
                        <th>Peso Jabas</th>
                        <th>Peso neto</th>
                        <th>Acción</th>
                      </tr>
                    </thead>
                    <tbody class="position-relative">
                      <tr v-if="purchaseSelected.purchasedetail.length == 0">
                        <td colspan="7" class="text-center">
                          No hay datos para mostrar
                        </td>
                      </tr>
                      <tr
                        v-else
                        v-for="(item, index) in purchaseSelected.purchasedetail"
                        :key="index"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.product.name }}</td>
                        <td>{{ item.subtotal }}</td>
                        <td>{{ item.quantity_unit }}</td>
                        <td>{{ item.quantity_java }}</td>
                        <td>{{ item.quantity_kg }}</td>
                        <td>
                          {{
                            parseFloat(
                              item.peso_java * item.quantity_java
                            ).toFixed(2)
                          }}
                        </td>
                        <td>{{ item.peso_neto }}</td>
                        <td class="text-center">
                          <button
                            type="button"
                            class="btn btn-sm btn-info text-white"
                            @click="mtdSelectItemDetail(item)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CCol>-->
              <CCol
                v-if="this.dispatch.detail.length > 0"
                xs="12"
                sm="12"
                md="12"
                lg="12"
              >
                <h4>DETALLE DE DESPACHO</h4>
              </CCol>
              <CCol
                xs="12"
                sm="12"
                md="12"
                lg="12"
              >
                <div class="position-relative table-responsive">
                  <table class="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Producto</th>
                        <th>Vivo</th>
                        <th>Benefeciado</th>
                      </tr>
                    </thead>
                    <tbody class="position-relative" v-if="this.dispatch.detail.length > 0">
                      <tr
                        v-for="(item, index) in this.dispatch.detail"
                        :key="index"
                      >
                        <td>
                          <b>{{ index + 1 }}</b>
                        </td>
                        <td>{{ item.name }}</td>
                        <td>
                          {{
                            parseFloat(item.detail[0].neto_kg).toFixed(2)
                          }}
                          KG
                        </td>
                        <td>
                          {{ parseFloat(item.detail[1].neto_kg).toFixed(2) }} KG
                        </td>
                      </tr>
                    </tbody>
                    <tbody class="position-relative" v-else>
                      <tr class="text-center">
                        <td colspan="4">NO HAY DETALLE PARA MOSTRAR</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CCol>
              <CCol
                v-if="this.dispatch.detail.length > 0"
                xs="12"
                sm="12"
                md="12"
                lg="12"
              >
                <div class="d-flex justify-content-evenly">
                  <button
                    type="button"
                    :disabled="cpButtonVaciar"
                    class="btn btn-sm w-100 text-white btn-danger"
                    @click="mtdVaciar"
                  >
                    <i class="fas fa-trash"></i>&nbsp; Vaciar Lista
                  </button>
                  <button
                    type="button"
                    :disabled="cpButtonSave"
                    class="btn btn-sm w-100 text-white ml-1 pl-1 btn-info"
                    @click="mtdSave"
                  >
                    <i class="fas fa-save"></i>&nbsp; Registrar Despacho
                  </button>
                </div>
              </CCol> 
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModalForm
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'xl'"
      :title="modal.title"
      :button="cp_button"
      :show.sync="modal.modal_form"
      @mtd_action="mtd_action"
      :color="'info'"
      :centered="false"
    >
      <CRow>
        <CCol xs="12" sm="12" md="12" lg="12">
          <div class="position-relative table-responsive">
            <table class="table table-bordered table-striped align-middle">
              <thead>
                <tr>
                  <th></th>
                  <th class="text-center" >
                    <b>VIVO</b>
                  </th>
                  <th class="text-center" >
                    <b>BENEFICIADO</b>
                  </th>
                </tr>
              </thead>
              <tbody class="position-relative">
                <tr>
                  <td style="vertical-align: middle; " rowspan="2">
                    JABA/CUBETAS
                  </td>
                  <td style="vertical-align: middle">
                    <CInput type="text" label="Cantidad Jabas" size="sm" placeholder="Cantidad de jabas" v-model="detItem.detail[0].quantity_jaba"/>
                  </td>
                  <td style="vertical-align: middle">
                    <CInput type="text" label="Cantidad Cubetas" size="sm" placeholder="Cantidad de cubetas" v-model="detItem.detail[1].quantity_cubeta"/>
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: middle">
                    <CInput type="text" label="Peso Jabas" size="sm" placeholder="Peso de jabas" v-model="detItem.detail[0].peso_jaba"/>
                  </td>
                  <td style="vertical-align: middle">
                    <CInput type="text" label="Peso Cubetas" size="sm" placeholder="Peso de cubetas" v-model="detItem.detail[1].peso_cubeta"/>
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: middle; ">CANTIDAD KG</td>
                  <td  style="vertical-align: middle">
                    <CInput type="text" label="Unidades" size="sm" placeholder="Unidades" v-model="detItem.detail[0].quantity_unit"/>
                    <CInput type="text" label="Kg" size="sm" placeholder="Kg" v-model="detItem.detail[0].quantity_kg"/>
                  </td>
                  <td >
                    <CInput type="text" label="Kg" size="sm" placeholder="Kg" v-model="detItem.detail[1].quantity_kg"/>
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: middle;">
                    <b>SUB-NETO</b>
                  </td>
                  <td><b>{{ cpTotalNetoVivo }}</b></td>
                  <td><b>{{ cpTotalNetoBene }}</b></td>
                </tr>
                <tr>
                  <td class="text-center" style="vertical-align: middle;" colspan="3">
                    <b>TOTAL NETO: {{ cpTotalNeto }}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CCol>
      </CRow>
    </CModalForm>

    <CModalForm
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'lg'"
      :title="modalSave.title"
      :button="cp_button_save"
      :show.sync="modalSave.modal_form"
      @mtd_action="mtdCommitSave"
      :color="'info'"
      :centered="true"
    >
      <CRow>
        <CCol xs="12" sm="12" md="12" lg="12">
          <div class="d-flex flex-wrap">
            <button
              v-for="(item, index) in dealer"
              :key="index"
              type="button"
              :class="
                dispatch.dealer == item.id
                  ? 'btn btn-sm w-100 text-white ml-1 pl-1 mt-1 btn-info'
                  : 'btn btn-sm w-100 text-white ml-1 mt-1 pl-1 btn-secondary text-dark'
              "
              @click="mtdSelectDealer(item.id)"
            >
              <b><i class="fas fa-dolly"></i>&nbsp; {{ item.name }}</b>
            </button>
          </div>
        </CCol>
      </CRow>
    </CModalForm>
  </div>
</template>
  
  <script>
import { mapActions } from "vuex";
import { bus } from "../../main";
import cSelectForm from "../../components/shared/inputs/cSelect.vue";
import CModalForm from "../../components/shared/modals/cModalForm.vue";

export default {
  name: "c-process-compras",
  components: { cSelectForm, CModalForm },
  data() {
    return {
      stock:[],
      purchase: 0,
      dealer: [],
      dispatch: {
        purchase: null,
        provider: null,
        dealer: null,
        detail: [],
      },
      modal: {
        action: "",
        title: "",
        modal_form: false,
      },
      detItem: {
        name: "",
          product_id: "",
          total: 0,
          totalNeto: 0,
          detail: [           
            {
              type: "VIVO",
              quantity_jaba: "",
              peso_jaba: "",
              quantity_kg: "",
              quantity_unit: "",
              neto_kg: "",
            },
            {
              type: "BENEFICIADO",
              quantity_cubeta: "",
              peso_cubeta: "",
              quantity_kg: "",
              neto_kg: "",
            },
          ],
      },
      modalSave: {
        action: "",
        title: "",
        modal_form: false,
      },
    };
  },
  created() {
    this.mtdGetdata();
  },
  computed: {
    cp_button() {},
    cpTotalNetoVivo() {
      let neto = 0;
      if (
        // this.detItem.detail[1].quantity_kg > 0 &&
        this.detItem.detail[0].quantity_jaba > 0 &&
        this.detItem.detail[0].peso_jaba > 0 &&
        this.detItem.detail[0].quantity_kg > 0
      ) {
        let jaba = parseFloat(
          this.detItem.detail[0].quantity_jaba *
            this.detItem.detail[0].peso_jaba
        );
        neto = parseFloat(this.detItem.detail[0].quantity_kg - jaba).toFixed(
          2
        );
      }
      if (neto < 0) {
        neto = 0;
      }
      this.detItem.detail[0].neto_kg = neto;
      return neto;
    },
    cpTotalNetoBene() {
      let neto = 0;
      if (
        // this.detItem.detail[1].quantity_kg > 0 &&
        this.detItem.detail[1].quantity_cubeta > 0 &&
        this.detItem.detail[1].peso_cubeta > 0 &&
        this.detItem.detail[1].quantity_kg > 0
      ) {
        let cubeta = parseFloat(
          this.detItem.detail[1].quantity_cubeta *
            this.detItem.detail[1].peso_cubeta
        );
        neto = parseFloat(this.detItem.detail[1].quantity_kg - cubeta).toFixed(
          2
        );
      }
      if (neto < 0) {
        neto = 0;
      }
      this.detItem.detail[1].neto_kg = neto;
      return neto;
    },
    cpTotalNeto() {
      let neto = 0;
      if (
        this.detItem.detail[0].neto_kg >= 0 &&
        this.detItem.detail[1].neto_kg >= 0
      ) {
        neto =
          parseFloat(this.detItem.detail[0].neto_kg) +
          parseFloat(this.detItem.detail[1].neto_kg);
      }
      this.detItem.totalNeto = parseFloat(neto).toFixed(2);
      return parseFloat(neto).toFixed(2);
    },
    cpTotal() {
      let t1 =
        this.detItem.detail[0].selectPrice.length > 0 &&
        this.detItem.detail[0].neto_kg.length > 0
          ? parseFloat(
              this.detItem.detail[0].selectPrice *
                this.detItem.detail[0].neto_kg
            ).toFixed(2)
          : 0.0;
      let t2 =
        this.detItem.detail[1].selectPrice.length > 0 &&
        this.detItem.detail[1].neto_kg.length > 0
          ? parseFloat(
              this.detItem.detail[1].selectPrice *
                this.detItem.detail[1].neto_kg
            ).toFixed(2)
          : 0.0;
      let t = parseFloat(t1) + parseFloat(t2);
      this.detItem.total = parseFloat(t).toFixed(2);
      return parseFloat(t).toFixed(2);
    },
    cpButtonVaciar() {
      if (this.dispatch.detail.length > 0) return false;
      return true;
    },
    cpButtonSave() {
      if (this.dispatch.detail.length > 0)
        return false;
      return true;
    },
    cp_button_save() {
      if (this.dispatch.dealer != null) return false;
      return true;
    },
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdBack: function () {  
      this.$emit("mtdBack");
    },
    mtdGetdata: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/process/dispatch/" +
          this.$store.getters.get_campu.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.stock = response.data.stock;
          this.purchase = response.data.purchase;
          this.dealer = response.data.dealer;
          this.dealer.push({
            id:0,
            name:"TIENDA"
          },
          {
            id:'camal',
            name:"CAMAL"
          })
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtdVerifDetail: function (idVerif) {
      let boo = false;
      this.dispatch.detail.forEach((element) => {
        if (element.product_id == idVerif) {
          boo = true;
        }
      });
      return boo;
    },
    mtdSelectItemDetail: function (detail) {
      if (!this.mtdVerifDetail(detail.product.id)) {
        this.modal.modal_form = true;
        this.modal.action = "store";
        this.modal.title = "AGREGAR DESPACHO DE " + detail.product.name+": "+detail.amount+"Kg";
        this.detItem = {
          name: detail.product.name,
          product_id: detail.product.id,
          total: 0,
          totalNeto: 0,
          detail: [           
            {
              type: "VIVO",
              quantity_jaba: "",
              peso_jaba: "",
              quantity_kg: "",
              quantity_unit: "",
              neto_kg: "",
            },
            {
              type: "BENEFICIADO",
              quantity_cubeta: "",
              peso_cubeta: "",
              quantity_kg: "",
              neto_kg: "",
            },
          ],
        };
      } else {
        bus.$emit("alert", {
          color: "warning",
          message: "Detalle ya agregado!",
        });
      }
    },
    mtd_action: function () {
      this.dispatch.detail.push(this.detItem);
      this.detItem={
        name: "",
          product_id: "",
          total: 0,
          totalNeto: 0,
          detail: [           
            {
              type: "VIVO",
              quantity_jaba: "",
              peso_jaba: "",
              quantity_kg: "",
              quantity_unit: "",
              neto_kg: "",
            },
            {
              type: "BENEFICIADO",
              quantity_cubeta: "",
              peso_cubeta: "",
              quantity_kg: "",
              neto_kg: "",
            },
          ],
      },
      this.modal = {
        action: "",
        title: "",
        modal_form: false,
      };
    },
    mtdVaciar: function () {
      this.dispatch.detail = [];
    },
    mtdSave: function () {
      this.modalSave.modal_form = true;
      this.modalSave.title = "ESCOJE UN REPARTIDOR";
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    mtdSelectDealer: function (id) {
      this.dispatch.dealer = id;
    },
    mtdCommitSave: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/process/dispatch/store/" +
          this.$store.getters.get_campu.id,
        token: this.$store.getters.get__token,
        params: this.dispatch,
      })
        .then((response) => {
          if (!response.error) {
            this.modalSave = {
              action: "",
              title: "",
              modal_form: false,
            };
            
            // if(this.dispatch.dealer>0){
              window.open(
                this.$store.getters.get__url +
                  "/ticket/despacho/" +
                  response.data.id
              );
            // }
            bus.$emit("alert", {
              color: "success",
              message: "Despacho registrado correctamente!!",
            });
            this.dispatch = {
              purchase: null,
              provider: null,
              dealer: null,
              detail: [],
            };
            this.mtdGetdata();
          } else {
            bus.$emit("alert", {
              color: "warning",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    /**  */
    mtdSelectPurchase: function (payload, pos) {
      // console.log(payload);
      // this.keyPurchaseSelected = pos;
      // this.dispatch.provider = payload.provider_id;
      // this.dispatch.purchase = payload.id;
      // this.purchaseSelected = payload;
    },
    mtdGetClasesPurchase: function (state, pos) {
      let clases = "";
      if (pos == this.keyPurchaseSelected) {
        clases = "list-group-item list-group-item-action active";
      } else {
        switch (state) {
          case 1:
            clases = "list-group-item list-group-item-primary";
            break;

          default:
            break;
        }
      }
      return clases;
    },
    mtdSelectPrice: function (pos, value) {
      this.detItem.detail[pos].selectPrice = value;
    },
  },
};
</script>
  
<style scoped>
.list-group-item {
  cursor: pointer;
}
</style>